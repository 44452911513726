import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { fetchData } from "../helpers/fetchData";
import Logo from "../images/icons/timeline.png";
import defaultpng from "../images/icons/default.jpg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function Events(props) {
  let navigate = useNavigate();
  let { year_param } = useParams();
  const [events, setEvents] = useState(false);
  const [year, setYear] = useState(year_param || new Date().getFullYear());

  useEffect(() => {
    getEvents(year_param);
    setYear(year_param);
  }, [year_param]);

  const getEvents = async (year) => {
    props.loderFunction(true);
    try {
      const res = await fetchData("getOrganizationBlogsV1", "Support", "POST", {
        OrganizationID: process.env.REACT_APP_ORGANIZATION_ID,
        // category: 278,
        year: year,
        orderBy: year,
      });
      if (res?.data && res?.status !== "error") {
        let dateSplit;
        res?.data.forEach((item) => {
          dateSplit = item?.postdate.split("-");
          item["postdate"] = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
        });
        setEvents(res.data);
      }
    } catch (err) {
      props.loderFunction(false);
    }
    props.loderFunction(false);
  };
  const handleSelect = (e) => {
    navigate({
      pathname: "/timeline/" + e,
    });
    setYear(e);
    setEvents([]);
    getEvents(e);
  };
  return (
    <div className="container">
      <DropdownButton
        id="dropdown-basic-button"
        title={year}
        variant="secondary"
        onSelect={handleSelect}
      >
        <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
        <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
        <Dropdown.Item eventKey="2022">2022</Dropdown.Item>
        <Dropdown.Item eventKey="2021">2021</Dropdown.Item>
        <Dropdown.Item eventKey="2020">2020</Dropdown.Item>
        <Dropdown.Item eventKey="2019">2019</Dropdown.Item>
        <Dropdown.Item eventKey="2018">2018</Dropdown.Item>
        <Dropdown.Item eventKey="2017">2017</Dropdown.Item>
        <Dropdown.Item eventKey="2016">2016</Dropdown.Item>
        <Dropdown.Item eventKey="2015">2015</Dropdown.Item>
        <Dropdown.Item eventKey="2014">2014</Dropdown.Item>
      </DropdownButton>

      {events && (
        <VerticalTimeline lineColor="#0e09008f">
          {events?.map((item, ind) => {
            return (
              <VerticalTimelineElement
                key={ind}
                className="vertical-timeline-element--work rounded"
                contentStyle={{
                  background: "#66520acc",
                  color: "#fff",
                  cursor: "pointer",
                }}
                contentArrowStyle={{ borderRight: "7px solid  #66520acc" }}
                date={item.postdate}
                iconStyle={{
                  background: "rgb(201, 172, 92)",
                  color: "#fff",
                  cursor: "pointer",
                }}
                icon={
                  <img
                    src={Logo}
                    className="img-thumbnail rounded-circle"
                    title={`${item?.blog_title} - ${item?.postdate}`}
                    alt={item?.page_url}
                  />
                }
                iconOnClick={(item) => {
                  if (item?.target?.alt) {
                    navigate({
                      pathname: "/event/" + item?.target?.alt,
                    });
                  }
                }}
                onTimelineElementClick={(item) => {
                  if (item?.target?.alt) {
                    navigate({
                      pathname: "/event/" + item?.target?.alt,
                    });
                  }
                }}
              >
                <div className="row gy-4 gx-4" name={item?.page_url}>
                  <img
                    src={item?.image != null ? item?.image : defaultpng}
                    className="w-100 p-0 border border-secondary img-fluid rounded"
                    title={`${item?.blog_title} - ${item?.postdate}`}
                    alt={item?.page_url}
                  />
                  <h3 className="vertical-timeline-element-title">
                    {item.blog_title}
                  </h3>
                  <p>{item.short_description}</p>
                </div>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      )}
    </div>
  );
}

export default Events;
