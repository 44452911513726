import React from "react";
import { useParams } from "react-router-dom";
import eventpng from "../images/page/events/event.jpg";
import memorialEvent from "../images/page/events/memorial-event.jpg";

function EventView(props) {

  const params = useParams();

  if (params?.slug !== "") {
    let event = {};
    if (params?.slug == "puramalai") {

      event.cover = eventpng;
      event.blog_title = "Puramalai Naadu Trail";
      event.description = "<a href = 'https://docs.google.com/forms/d/e/1FAIpQLScguQGds3_sonH-3ONDEOG8g0njm94FPJCNYs_DCWt95N8TTQ/viewform' target='_blank'><i class='fa fa-hand-o-right animated-icon' style='font-size:30px;color:brown'></i><b>Click to fillout the Puramalai trail form</b></a>"
    }
    else if (params?.slug == "memorial") {

      event.cover = memorialEvent;
      event.blog_title = "Memorial Lecture Series";
      event.description = "<a href = 'https://docs.google.com/forms/d/e/1FAIpQLSeis_lwzfQh81nrwubAHdqKzuVyTdu2IYjb0XZewkPfA8qkaw/viewform' target='_blank'><i class='fa fa-hand-o-right animated-icon' style='font-size:30px;color:brown'></i><b>Click to fillout the Memorial lecture form</b></a>"
    }

  return (
    <div className="container">
      {/* <h2 className="col-md-12 mb-2 text-center">{event?.blog_title}</h2> */}
      {event?.cover != null && (
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-sm-12 col-12">
            <img className="w-100 img-fluid rounded" src={event?.cover} alt={event?.blog_title}></img>
          </div>
        </div>
      )}
      <div className="row justify-content-center">
        <p
          className="col-lg-10 col-md-10 col-sm-12 col-12 mt-4 p-2 text-align-justify text-wrap"
          dangerouslySetInnerHTML={{ __html: event?.description }}
        ></p>
      </div>
    </div>
  );
  }
}

export default EventView;
