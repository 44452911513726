import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import event from "../images/page/events/event.jpg";
import independenceEvent from "../images/page/events/independence-event.jpg";
import pandyasEvent from "../images/page/events/pandyasEvent.jpg";
import defaultpng from "../images/icons/default.jpg";

function Tour_Trail(props) {
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");


  const handleImageClick = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  let data = [];
  
  let item = {};
  item.page_url = "puramalai";
  item.image = event;
  item.blog_title = "Puramalai Naadu Trail";
  item.short_description = "<a href = 'https://docs.google.com/forms/d/e/1FAIpQLScguQGds3_sonH-3ONDEOG8g0njm94FPJCNYs_DCWt95N8TTQ/viewform' target='_blank'><i class='fa fa-hand-o-right animated-icon' style='font-size:20px;color:brown'></i><b>Register here!</b></a>";
  data.push(item);
  
  let independence_item = {};
  independence_item.page_url = "independence";
  independence_item.image = independenceEvent;
  independence_item.blog_title = "Freedom Trail";
  independence_item.description  = 'This <img data-emoji="🇮🇳" class="iiJ4W" alt="🇮🇳" aria-label="🇮🇳" draggable="false" src="https://fonts.gstatic.com/s/e/notoemoji/15.1/1f1ee_1f1f3/512.png=s20" loading="lazy"> Independence Day weekend (18/08/2024), explore the courage of our Freedom Heroes with FoHS. Stop at <b>ETTAYAPURAM</b> to see the home of poet Bharathiyar, visit the samadi of carnatic musical legend Dikshitar and the home of literary genius Umar Pulavar. Let us go on to <b>OTTAPIDARAM</b> to see the rich life and library of Patriot VO Chidambaramnar, learn how he gave it all to our nation. Enjoy reliving the life of fiery Veerapandian Kattabomman at his fort & Jakkamal temple at <b>PANCHALANKURICHI</b> followed by his memorial at <b>KAITHAR</b>. <br><br><i>Let\'s celebrate the legacy of our freedom. </i>';
  independence_item.short_description = "<a href = 'https://docs.google.com/forms/d/e/1FAIpQLSc3fjzQTPyIL_Bc82tYiC9_9ENmyWECWDzsqbBVni61DQskVg/viewform' target='_blank'><i class='fa fa-hand-o-right animated-icon' style='font-size:20px;color:brown'></i><b>Register here!</b></a>";
  data.push(independence_item);

  let pandyas_item = {};
  pandyas_item.page_url = "pandyas";
  pandyas_item.image = pandyasEvent;
  pandyas_item.blog_title = "Pandyas Trail";
  pandyas_item.description  = "Take a break to enjoy the Early Pandya rock cut temples and hidden Jain caves of our region. We have Ms Deepika, an expert from Chennai. It's a van day trip and registration is compulsory! We finish by relaxing in Courtallam private falls and then coming back to Tirunelveli.";
  pandyas_item.short_description = "<a href = 'https://docs.google.com/forms/d/e/1FAIpQLSen-3DWzOpFjidtTAe31KXuz-RNnWab-OuUaUTbrwX0caFl2A/viewform' target='_blank'><i class='fa fa-hand-o-right animated-icon' style='font-size:20px;color:brown'></i><b>Register here!</b></a>";
  data.push(pandyas_item);

  return (
    <section className="mt-5">
        <div className="band">
          {data.map((item, ind) => {
            return (
              <div
                className={`item-${ind}`}  key={ind} id="multiple"
              >
                <a className="my-card">
                  <div
                    className="thumb"
                    style={{
                      backgroundImage: `url(${
                        item?.image != null || "" ? item?.image : defaultpng
                      })`,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageClick(item?.image || defaultpng);
                    }}
                  ></div>
                </a>
              </div>
            );
          })}
        </div>
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Body>
          <img className="w-100 mh-100 img-fluid rounded" src={modalImage} alt="Event"/>
          </Modal.Body>
        </Modal>
    </section>
  );
}

export default Tour_Trail;
