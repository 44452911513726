import Slider1 from "../images/slider/slider_1.jpg";
import Slider2 from "../images/slider/slider_2.jpg";
import Slider3 from "../images/slider/slider_3.jpg";
import Slider4 from "../images/slider/slider_4.png";

const Slider = () => {
  return (
    <section>
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="2000"
        data-bs-touch="false">
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"></button>
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide-to="1"
            aria-label="Slide 2"></button>
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide-to="2"
            aria-label="Slide 3"></button>
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide-to="3"
            aria-label="Slide 4"></button>
        </div>
        <div className="carousel-inner rounded">
          <div className="carousel-item active">
            <img src={Slider1} className="d-block w-100" alt="Slider1" />
          </div>
          <div className="carousel-item">
            <img src={Slider2} className="d-block w-100" alt="Slider2" />
          </div>
          <div className="carousel-item">
            <img src={Slider3} className="d-block w-100" alt="Slider3" />
          </div>
          <div className="carousel-item">
            <img src={Slider4} className="d-block w-100" alt="Slider4" />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev">
          <span
            className="carousel-control-prev-icon"
            title="Previous"
            aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next">
          <span
            className="carousel-control-next-icon text-white rounded"
            title="Next"
            aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </section>
  );
};

export default Slider;
