import React from "react";
import { FaRegAddressCard } from "react-icons/fa";
import { GoMail } from "react-icons/go";

function Contact() {
  return (
    <>
      <section>
          <div className="text-center">
            <h2>Get in Touch</h2>
            <div className="d-flex justify-content-center">
              <span className="mb-3 sub-underline"></span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex align-items-center">
              <div className="d-block text-center m-3">
                <FaRegAddressCard
                  className="w-100 text-secondary"
                  style={{ height: "50px" }}
                />
                <div className="mt-3">
                  Usha Jawahar, G-B, Orchard Court, 123/63 Chamiers Road, RA
                  puram Chennai - 600028
                </div>
                <GoMail
                  className="w-100 mt-3 text-secondary"
                  style={{ height: "60px" }}
                />
                <div className="mt-3">fohs.society@gmail.com</div>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
              <iframe
                defer
                className="w-100 rounded"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7774.094534800013!2d80.250842!3d13.032662!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267b4598dd71d%3A0xbf4d1eeeb9b21761!2s305%2C%201st%20Cross%20St%2C%20Cooperative%20Colony%2C%20Sri%20Ram%20Nagar%2C%20Alwarpet%2C%20Chennai%2C%20Tamil%20Nadu%20600018!5e0!3m2!1sen!2sin!4v1678199228527!5m2!1sen!2sin"
                width="auto"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
      </section>
    </>
  );
}

export default Contact;
