import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import eventsData from './Events.json';

function UpcomingEvents(props) {
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const navigate = useNavigate();
  const handleImageClick = (image) => {
    setModalImage(image);
    setShowModal(true);
  };
  useEffect(() => {
    const filteredEvents = eventsData.filter(event => event.upcoming_event == true);
    if (filteredEvents.length === 0) {
      navigate("/noevents");
    } else {
      setUpcomingEvents(filteredEvents);
    }
  }, []);
  const baseUrl = window.location.origin;
  const eventMeta = upcomingEvents[0] || {};

  return (
    <section className="mt-5">
      <Helmet>
        {/* <title>{eventMeta.blog_title || "Upcoming Event"}</title>
        <meta property="og:title" content={eventMeta.blog_title || "Upcoming Event"} />
        <meta property="og:description" content={eventMeta.short_description || "Join us for the upcoming event"} />
        <meta property="og:image" content={`${baseUrl}${eventMeta.image || "/default-thumb.jpg"}`} />
        <meta property="og:url" content={`${baseUrl}/${eventMeta.page_url || ""}`} />
        <meta property="og:type" content="website" /> */}
        <title>10th Annual FoHS Study Tour of Chozhanaadu</title>
        <meta property="og:title" content="10th Annual FOHS Tour of Chozhanaadu II" />
        <meta property="og:description" content="Join us for the 10th Annual FOHS Study Tour of Chozhanaadu II. Immerse yourself in the rich culture, history, and tradition of Chozhanaadu with expert guidance." />
        <meta property="og:image" content={`${baseUrl}/images/page/timeline/2024/10th Annual FOHS Tour Chozhanaadu II.jpg`} />
        <meta property="og:url" content={`${baseUrl}/upcoming-events/10th-annual-fohs-tour-chozhanaadu-II`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="band upcoming-band">
        {upcomingEvents.map((item, ind) => {
          return (
            <div className={`item-${ind}`}  key={ind} id="upcoming">
              <a className="my-card upcoming">
                <section className="mt-5">
                  <img src={item.image} alt={item.blog_title} className="upcoming-image" onClick={() => handleImageClick(item.image)}/>
                </section>
                <article>
                  <p className="m-0 mt-3" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                </article>
              </a>
            </div>
          );
        })}
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered dialogClassName="upcomingModal">
        <Modal.Body>
          <img className="w-100 mh-100 img-fluid rounded" src={modalImage} alt="Kasampatti Trail Event"/>
        </Modal.Body>
      </Modal>
    </section>
  );
}
export default UpcomingEvents;
