import React from "react";
import sharmila from "../images/page/about/Sharmila-President.jpg";
import chitraGopinath from "../images/page/about/Chitra-Secretary.jpg";
// import usha from "../images/page/about/Usha-Treasurer.webp";
// import shrividya from "../images/page/about/Shrividya-Murali.jpg";
import usha from "../images/page/about/Usha-Treasurer.png";
import dhruva from "../images/page/about/Dhruva-LegalCordinator.png";
import NirmalaVijaykumar from "../images/page/about/NirmalaVijaykumar.jpg";
// import SenthilKannan from "../images/page/about/SenthilKannan.jpg";
import Muthunarayanan from "../images/page/about/Muthunarayanan.jpg";
// import mvarun from "../images/page/about/mvarun.jpg";
import ramaasrikrishna from "../images/page/about/ramaasrikrishna.jpg";
// import prabakarthyagarajan from "../images/page/about/prabakarthyagarajan.jpg";
import Rajeev from "../images/page/about/Rajeev.png";
import ramanakumar from "../images/page/about/ramanakumar.jpg";
import nainashah from "../images/page/about/nainashah.jpg";
import arun from "../images/page/about/arun-ProgramCordinator.jpg";
import soundaram from "../images/page/about/soundraram-Executive.jpg";
import bala from "../images/page/about/bala-Executive.jpg";
import Selvakumar from "../images/page/about/Dr.-V.-Selvakumar.jpg";
import Gandhirajan from "../images/page/about/Gandhirajan.jpeg";
import Vedachalam from "../images/page/about/Vedachalam.jpeg";
import Subramanian from "../images/page/about/Kannan.png";

function About() {
  return (
    <div className="container">
      <div className="card-deck row justify-content-center gy-4 gx-4">
        <h2 className="text-center">Founding Members</h2>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={sharmila}
            alt="sharmila"
            title="Sharmila"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Sharmila</h5>
            <h6 className="card-title text-center text-secondary">President</h6>
            <p className="card-text text-align-justify text-wrap">
              Sharmila Devadoss is a serial technopreneur specialising in
              semiconductors, IoT and medtech. She was raised in Madurai amidst
              living heritage that led to her interest in Tamil history and
              heritage.
            </p>
          </div>
        </div>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={chitraGopinath}
            alt="chitraGopinath"
            title="Chitra Gopinath"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Chitra Gopinath</h5>
            <h6 className="card-title text-center text-secondary">Secretary</h6>
            <p className="card-text text-align-justify text-wrap">
              Chitra Gopinath is a student of Fine Arts and textile design. She
              engages in social work and works towards empowering women. She
              also has an ardent interest in anthropology and cultural heritage.
            </p>
          </div>
        </div>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img className="card-img-top" src={usha} alt="usha" title="Usha" />
          <div className="card-body">
            <h5 className="card-title text-center">Usha</h5>
            <h6 className="card-title text-center text-secondary">Treasurer</h6>
            <p className="card-text text-align-justify text-wrap">
              Usha Jawahar is a Botanist , Historian and Mental Health
              consultant has a passion to preserve and protect archaeological
              and heritage sites for future generations.
            </p>
          </div>
        </div>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={dhruva}
            alt="dhruva"
            title="Dhruva"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Dhruva</h5>
            <h6 className="card-title text-center text-secondary">
              Legal Coordinator
            </h6>
            <p className="card-text text-align-justify text-wrap">
              Dhruva is a Chennai-based lawyer with close to 3 decades of
              expertise covering sectors such as banking, corporate,
              intellectual and real property issues. Apart from being an ardent
              heritage and nature enthusiast, he also possesses a regional guide
              license from the Ministry of Tourism, India
            </p>
          </div>
        </div>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img className="card-img-top" src={arun} alt="arun" title="Arun" />
          <div className="card-body">
            <h5 className="card-title text-center">Arun</h5>
            <h6 className="card-title text-center text-secondary">
              Vice President
            </h6>
            <p className="card-text text-align-justify text-wrap">
              Arun Kumar is a management graduate is active in social forums and
              is a businessman from Madurai. His interest in heritage led him to
              join FoHS to continuously discover Tamil Nadu's culture-rich
              treasure trove
            </p>
          </div>
        </div>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={soundaram}
            alt="soundaram"
            title="Soundaram"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Soundaram</h5>
            <h6 className="card-title text-center text-secondary">
              Executive Committee Member
            </h6>
            <p className="card-text text-align-justify text-wrap">
              Soundaram Sathappan is a loving mother of two and in partnership
              with her better half in business and at life, she shares a passion
              for art and sculpture and a fascination for heritage buildings.
            </p>
          </div>
        </div>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={bala}
            alt="bala"
            title="Balamurugan"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Balamurugan</h5>
            <h6 className="card-title text-center text-secondary">
              Executive Committee Member
            </h6>
            <p className="card-text text-align-justify text-wrap">
              IAS Balamurugan is a banker by profession and a history enthusiast
              by passion. His interest lies in delving deep and discovering
              sangam literature. Indian heritage keeps him grounded despite his
              hectic work schedule.
            </p>
          </div>
        </div>
        {/* <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={shrividya}
            alt="shrividya"
            title="shrividya"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Shrividya Murali</h5>
            <h6 className="card-title text-center text-secondary">
              Executive Committee Member
            </h6>
            <p className="card-text text-align-justify text-wrap">
              Shrividya Murali is the Correspondent of Sherwood Hall Higher
              Secondary School Chennai. She is passionate about agriculture and
              a heritage enthusiast who is keen on restoring the beautiful
              heritage of India
            </p>
          </div>
        </div> */}
        {/* <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={prabakarthyagarajan}
            alt="prabakarthyagarajan"
            title="Prabakar Thyagarajan"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Prabakar Thyagarajan</h5>
            <h6 className="card-title text-center text-secondary">
              Executive Committee Member
            </h6>
            <p className="card-text text-align-justify text-wrap">
              Prabakar Thyagarajan is a physician who spent many years of his
              career in Boston, Massachusetts, and has recently returned to
              retire and settle in India. Joining FOHS is part of his effort to
              rediscover India and learn of its history, culture, and heritage.
            </p>
          </div>
        </div> */}
      </div>
      <div className="card-deck row justify-content-center gy-4 gx-4">
        <h2 className="text-center">Key Resources</h2>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={Vedachalam}
            alt="Vedachalam"
            title="Dr. V.Vedachalam"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Dr. V.Vedachalam</h5>
            <h6 className="card-title text-center text-secondary">
              Former Senior Epigraphist, Tamil Nadu State Archaeology
            </h6>
          </div>
        </div>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={Gandhirajan}
            alt="Gandhirajan"
            title="Mr. K.T.Gandhirajan"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Mr. K.T.Gandhirajan</h5>
            <h6 className="card-title text-center text-secondary">
              Art Historian and Rock Art Specialist
            </h6>
          </div>
        </div>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={Selvakumar}
            alt="Selvakumar"
            title="Dr. V.Selvakumar"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Dr. V.Selvakumar</h5>
            <h6 className="card-title text-center text-secondary">
              faculty member in Department of Maritime History and Marine
              Archaeology, Tamil University, Tanjavur
            </h6>
          </div>
        </div>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={Subramanian}
            alt="Subramanian"
            title="Prof. Kannan Subramanian"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Prof. Kannan Subramanian</h5>
            <h6 className="card-title text-center text-secondary">
            Associate Professor (Retd.), Department of History,
            Annamalai University, Chidambaram.
            </h6>
          </div>
        </div>
      </div>
      <div className="card-deck row justify-content-center gy-4 gx-4">
        <h2 className="text-center">Chapters</h2>
        <h3 className="text-center">FoHS Pandyas</h3>
        {/* <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={mvarun}
            alt="mvarun"
            title="M.V Arun"
          />
          <div className="card-body">
            <h5 className="card-title text-center">M.V Arun</h5>
            <h6 className="card-title text-center text-secondary">Convenor</h6>
            <p className="card-text text-align-justify text-wrap">
              Arun Vijaay Malli, a native of Madurai, holds an undergraduate
              degree from MKU and an MBA from Coimbatore PSG, with many varied
              business interests in and around Mdu and in Kodaikanal. Hailing
              from a family with a rich social standing and a nationalist
              background, he's much interested in history, sociology, heritage,
              travel and tourism, photography and networking. He's active in
              many social organisations that play an active role in working with
              people and serve the society.
            </p>
          </div>
        </div> */}
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={ramaasrikrishna}
            alt="ramaasrikrishna"
            title="Ramaa Srikrishna"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Ramaa Srikrishna</h5>
            <h6 className="card-title text-center text-secondary">
              Convenor
            </h6>
            <p className="card-text text-align-justify text-wrap">
              Ramaa Srikrishna is an avid reader and is deeply interested in
              history, mythology and Indian cultures and traditions. Having
              completed her post graduation in economics and a post graduate
              diploma in school counselling, she is also a student of vedanta.
              Ramaa enjoys travelling and takes initiatives to engage in
              meaningful discourse on matters concerning archaeology and
              heritage sites, anthropology and philosophy.
            </p>
          </div>
        </div>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={Rajeev}
            alt="rajeev"
            title="Rajeev"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Rajeev</h5>
            <h6 className="card-title text-center text-secondary">Co-convenor</h6>
            <p className="card-text text-align-justify text-wrap">
            Rajeev, a businessman from Madurai, has a passion for hiking and a deep interest in history, mythology, and Indian culture and traditions. He holds an MBA from PSG Tech in Coimbatore and is an avid traveler. Rajeev often takes the initiative to engage in meaningful discussions on topics related to archaeological and heritage sites, anthropology, and philosophy.
            </p>
          </div>
        </div>
        <h3 className="text-center">FoHS Thenpandyas</h3>
        {/* <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={SenthilKannan}
            alt="SenthilKannan"
            title="D. Senthil Kannan"
          />
          <div className="card-body">
            <h5 className="card-title text-center">D. Senthil Kannan</h5>
            <h6 className="card-title text-center text-secondary">Convenor</h6>
            <p className="card-text text-align-justify text-wrap">
              Mr. D. Senthil Kannan, is a graduate of Commerce and a
              Postgraduate of Business Administration. He runs a soft skills
              training academy in Tuticorin, in the name of PALMS. A socially
              oriented person, he supports many social causes such as free
              education, blood donation and environmental care. He believes in
              the philosophy of, “Think Global, Act Local”. He has a wide array
              of interest, and likes to travel and explore new places, meet new
              people, learn new things and share his experiences.
            </p>
          </div>
        </div> */}
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={NirmalaVijaykumar}
            alt="NirmalaVijaykumar"
            title="Dr Nirmala Vijaykumar"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Dr Nirmala Vijaykumar</h5>
            <h6 className="card-title text-center text-secondary">
              Convenor
            </h6>
            <p className="card-text text-align-justify text-wrap">
              Dr Nirmala Vijaykumar is a Consultant in Assisted Reproduction and
              Fertility Surgery at Queens Fertility Center, Tirunelveli. Dr
              Nirmala is an alumni of Tirunelveli Medical College 1992 batch and
              did her Masters in Obstetrics and Gynecology from SRMC, Chennai.
              She worked in UK from 2003-2005 and earned her Membership degree
              from Royal College of Obstetrics and Gynecology, London, UK. A
              dedicated athlete and cyclist, Dr Nirmala has been an inspiration
              to many. Through FoHS, she is actively spreading awareness on the
              local Heritage sites and enduring culture of the region amongst
              the public.
            </p>
          </div>
        </div>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={Muthunarayanan}
            alt="Muthunarayanan"
            title="Dr Muthunarayanan"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Dr Muthunarayanan</h5>
            <h6 className="card-title text-center text-secondary">Co-convenor</h6>
            <p className="card-text text-align-justify text-wrap">
            {/* FoHS Thenpandyas is the society covering Heritage trails and culture related events in the Southern part of Tamilnadu.

            They cover the 4 districts of Tenkasi, Tirunelveli, Tuticorin and Kanyakumari. 
            Within this region lie myriad Jain caves, rock cut temples and relics of Pandya, Chola, Chera kings distributed amidst rich eco-diversity. */}
            Dr Muthunarayan is a heritage enthusiast and is serving as the co- conveynor of FoHS Thenpandyas between 2024-26. 
            A doctor by profession, he is the Chief Anesthesiologist Consultant at AVM Hospitals, Tuticorin. 
            An avid ornithologist and photographer, he has is keen on documentation of heritage sites. He has also learnt to read the ancient vattelutthu Tamil script.       </p>
          </div>
        </div>
      </div>
      <div className="card-deck row justify-content-center gy-4 gx-4">
        <h3 className="text-center">FoHS Pallavas</h3>
        {/* <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={prabakarthyagarajan}
            alt="prabakarthyagarajan"
            title="Prabakar Thyagarajan"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Prabakar Thyagarajan</h5>
            <h6 className="card-title text-center text-secondary">Convenor</h6>
            <p className="card-text text-align-justify text-wrap">
              Prabakar Thyagarajan is a physician who spent many years of his
              career in Boston, Massachusetts, and has recently returned to
              retire and settle in India. Joining FOHS is part of his effort to
              rediscover India and learn of its history, culture, and heritage.
            </p>
          </div>
        </div> */}
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={ramanakumar}
            alt="ramanakumar"
            title="Ramana Kumar"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Ramana Kumar</h5>
            <h6 className="card-title text-center text-secondary">Convenor</h6>
            <p className="card-text text-align-justify text-wrap">
              Ramana Kumar is a Chartered Accountant turned Lawyer who is a
              passionate photographer with distinctions of FFIP, EFIAP and PPSA
              . He has had awards and exhibitions in about 60 countries. He has
              documented many heritage sites across India.
            </p>
          </div>
        </div>
        <div className="m-4 p-0 card card-hover" style={{ width: "20rem" }}>
          <img
            className="card-img-top"
            src={nainashah}
            alt="nainashah"
            title="Naina Shah"
          />
          <div className="card-body">
            <h5 className="card-title text-center">Naina Shah</h5>
            <h6 className="card-title text-center text-secondary">
              Co-convenor
            </h6>
            <p className="card-text text-align-justify text-wrap">
            Mrs Naina Shah – Environmentalist, Entrepreneur & Businesswoman
            Enterprising, mindful and sensitive to the current ecological needs.
            She works on sustainable solutions for ecological needs for Climate change. 
            Her interest are serving the society and working with different platforms.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
