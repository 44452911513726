import React from "react";
import Slider from "../inc/Slider";
import design from "../images/design/Design.png";
import bull from "../images/page/home/home1.jpg";

function Home() {
  return (
    <div className="container">
      <Slider />
      <section>
        <div className="m-2 p-2">
          <div className="row">
            {[1, 2, 3, 4].map((item) => {
              return (
                <div className="col-lg-3 col-md-3 col-sm-3 col-3" key={item}>
                  <img className="w-100" src={design} alt="design" />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section>
        <div className="container lh-lg">
          <div className="row">
            <div className="col-md-12">
              <p className="text-align-justify">
                India abounds with sites that are of rich historical and
                archaeological value. However, many of these sites languish, due
                to lack of upkeep and rampant vandalism. While efforts are being
                made by the government to care for these sites, in a country of
                India's size, heritage cannot be protected, unless people's
                participation is active. To nurture the active participation of
                people in heritage- related issues, Friends of Heritage Sites
                (FoHS) has been established. A non-profit organisation, that
                aims to create interest in heritage and the environment and
                thereby create a more people-centric approach to preserving
                heritage, FoHS comprises members from various walks of life.
                These individuals have come together with the objective of :
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="row m-2 clearfix">
          <ul className="col-lg-8 col-md-8 col-sm-12 col-12 text-align-justify lh-lg">
            <li>Creating a social space for heritage enthusiasts</li>
            <li>
              Conserving ecology and natural heritage in relation to heritage
              monuments.
            </li>
            <li>
              Making Tamil Nadu the leader in Heritage Tourism, by adopting
              several strategies
            </li>
            <ul>
              <li>
                Addressing environmental issues in heritage sites, by
                coordinating with government bodies and working with local
                communities, to enhance their livelihood.
              </li>
              <li>
                Identifying and promoting archaeologically and historically
                significant remote and less-known sites in the region.
              </li>
              <li>Promoting less-known art and craft forms.</li>
            </ul>
            <li>
              Undertaking awareness programmes for people from different walks
              and life.
            </li>
            <li>
              Promoting the interest and welfare of the local community by
              creating ‘Heritage Hubs’ that would showcase the tradition and
              intangible ethos of the region.
            </li>
          </ul>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex justify-content-center align-items-center">
            <img className="img-fluid w-100 rounded" src={bull} alt="bull" />
          </div>
        </div>
      </section>
      <section>
        <div className="container lh-lg">
          <div className="row">
            <div className="col-md-12">
              <p className="text-align-justify">
                As a first step towards building a strong public base that would
                be sensitive towards the arts, FoHS has pioneered several
                programmes such as heritage tours to rare lesser known
                architectural sites, lectures and unique art revival initiatives
                like Vichitrachitta - Mamallapuram- based sculpture project and
                Chithirakoodal - a Pandya region paintings project.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
