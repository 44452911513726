import React , { useState } from "react";
import { Modal } from 'react-bootstrap';
import pressEvent from "../images/page/events/press-event.jpg";
import defaultpng from "../images/icons/default.jpg";

function FohsPress() {
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleImageClick = (image) => {
    setModalImage(image);
    setShowModal(true);
  };


  let press_data = [];
  let press_item = {};

  press_item.image = pressEvent;
  press_data.push(press_item);

  return (
    <section className="mt-5">
        <div className="band">
          {press_data.map((item, ind) => {
            return (
              <div
                className={`item-${ind}`} key={ind}
              >
                <a className="my-card">
                  <div
                    className="thumb"
                    style={{
                      backgroundImage: `url(${
                        item?.image != null || "" ? item?.image : defaultpng
                      })`,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageClick(item?.image || defaultpng);
                    }}
                  >                    
                  </div>
                </a>
              </div>
            );
          })}
        </div>
        <Modal id="press-modal" show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body>
        <img className="w-100 mh-100 img-fluid rounded" src={modalImage} alt="Event"/>
        </Modal.Body>
      </Modal>

    </section>
  );
}

export default FohsPress;
