import React from "react";

function Gac() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center gy-4 gx-4">
        {/* <Stories
        stories={stories}
        defaultInterval={1500}
        width={800}
        height={600}
      /> */}
        <iframe
          title="1"
          allowFullScreen
          allow="autoplay"
          frameBorder="0"
          width="800"
          height="600"
          src="https://embed.culturalspot.org/embedv2/story/HgWhnVO44mWnIw"
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
        <iframe 
          title="2"
          allowfullscreen 
          allow="autoplay" 
          frameBorder="0" 
          width="800" 
          height="600" 
          src="https://embed.culturalspot.org/embedv2/story/vwXBHb9bgAnxZQ" 
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
        <iframe
          title="3"
          allowFullScreen
          allow="autoplay"
          frameBorder="0"
          width="800"
          height="600"
          src="https://embed.culturalspot.org/embedv2/story/ewWBrYIFOxSJtA"
          referrerPolicy="allow-origin-when-cross-origin"
        ></iframe>
        <iframe
          title="4"
          allowFullScreen
          allow="autoplay"
          frameBorder="0"
          width="800"
          height="600"
          src="https://embed.culturalspot.org/embedv2/story/XgXxG3f2UTvPHQ"
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
        <iframe
          title="5"
          allowFullScreen
          allow="autoplay"
          frameBorder="0"
          width="800"
          height="600"
          src="https://embed.culturalspot.org/embedv2/story/owWh2KXr2j75Lw"
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
      </div>
    </div>
  );
}

export default Gac;
