import React from "react";
import maintain from "../images/page/home/maintenance.png";
import defaultpng from "../images/icons/default.jpg";

function Maintenance(props) {

  let maintain_data = [];
  let maintain_item = {};

  maintain_item.image = maintain;
  maintain_data.push(maintain_item);


  return (
    <section className="mt-5">
      <div className="band">
        {maintain_data.map((item, ind) => {
          return (
            <div
              className={`item-${ind}`} key={ind}
            >
              <a className="my-card" id = "maintain">
                <div
                  className="thumb"
                  style={{
                    backgroundImage: `url(${
                      item?.image != null || "" ? item?.image : defaultpng
                    })`,
                  }}
                >                    
                </div>
              </a>
            </div>
          );
        })
        }
      </div>

    </section>
  );
}

export default Maintenance;
