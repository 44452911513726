import axios from "axios";

export const fetchData = async (actionVal, moduleVal, method, data) => {
  try {
    const res = await axios({
      method,
      url: process.env.REACT_APP_URL,
      params: {
        actionVal,
        moduleVal,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_TOKEN,
        // "x-token": `${token}`,
      },
      data,
    });
    return res?.data;
  } catch (error) {
    throw new Error(`${error}`);
  }
};
