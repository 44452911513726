const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <section>
      <div className="container">
        <div className="m-4 pt-2">
          <div className="text-center text-muted mt-2 fw-bolder">
            Copyright @ {currentYear} Powered by : FoHS
          </div>
        </div>
      </div>
    </section>
  );
};  
export default Footer;
